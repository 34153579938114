<app-panel *ngIf="!!client" [title]="title">
  <!--  -->
  <section class="flex flex-col gap-4 text-sm">
    <!--  -->
    <!--  -->
    <app-panel title="Means Test Tier Assignment">
      <section *ngIf="hasMeansTestTierAssignments" class="flex flex-col">
        <div class="flex flex-col gap-4">
          <section class="w-full flex gap-4">
            <div class="w-full">
              <p class="font-medium mb-4">
                Latest Means Test Tier Assignment Detail
              </p>
              <crud-field
                label="Effective date"
                [value]="dateFormat(mttAssignment?.effective_date)"
              />
              <crud-field
                label="End date"
                [value]="dateFormat(mttAssignment?.end_date)"
              />
              <crud-field
                label="Need financial assistance"
                [value]="
                  !!mttAssignment?.need_financial_assistance ? 'Yes' : 'No'
                "
              />
            </div>
            <section
              *ngIf="mttAssignment?.means_test_tier as meansTestTier"
              class="w-full"
            >
              <p class="font-medium mb-4">Means Test Tier Details</p>
              <crud-field
                label="Tier name"
                [value]="meansTestTier?.tier_name"
              />
              <crud-field
                label="Tier range"
                [value]="meansTestTier?.tier_range"
              />
              <crud-field
                label="Monthly Fee Rounded Up To Nearest 5 Cent"
                [value]="meansTestTier?.monthly_fee_inc_gst_rounded"
              />
              <crud-field
                label="Sub programme"
                [value]="meansTestTier?.sub_programme"
              />
              <crud-field
                label="Citizenship"
                [value]="meansTestTier?.citizenship"
              />
            </section>
          </section>
          <mat-divider
            *ngIf="
              !!mttAssignment?.need_financial_assistance ||
              !!mttAssignment?.deviated_means_test_tier
            "
          ></mat-divider>
          <section class="w-full flex gap-4">
            <!--  -->
            <div
              *ngIf="!!mttAssignment?.need_financial_assistance"
              class="w-full"
            >
              <crud-field
                label="Deviation reason"
                [value]="mttAssignment?.deviation_reason"
              />
              <crud-field
                label="Deviated effective date"
                [value]="dateFormat(mttAssignment?.deviated_effective_date)"
              />
              <crud-field
                label="Deviated end date"
                [value]="dateFormat(mttAssignment?.deviated_end_date)"
              />
            </div>
            <!--  -->
            <section
              *ngIf="
                mttAssignment?.deviated_means_test_tier as deviatedMeansTestTier
              "
              class="w-full"
            >
              <p class="font-medium mb-4">Deviated Means Test Tier Details</p>
              <crud-field
                label="Tier name"
                [value]="deviatedMeansTestTier?.tier_name"
              />
              <crud-field
                label="Tier range"
                [value]="deviatedMeansTestTier?.tier_range"
              />
              <crud-field
                label="Monthly Fee Rounded Up To Nearest 5 Cent"
                [value]="deviatedMeansTestTier?.monthly_fee_inc_gst_rounded"
              />
              <crud-field
                label="Sub programme"
                [value]="deviatedMeansTestTier?.sub_programme"
              />
              <crud-field
                label="Citizenship"
                [value]="deviatedMeansTestTier?.citizenship"
              />
            </section>
          </section>
        </div>
      </section>
      <section *ngIf="!hasMeansTestTierAssignments">
        <p class="font-medium">
          {{ client?.name_used_in_goals ?? client.name | titlecase }} has no
          assigned Means Test Tier
        </p>
      </section>
    </app-panel>
    <!--  -->
    <!--  -->
    <app-panel title="ETS Subsidy Assignment">
      <section *ngIf="hasTransportApplication" class="flex flex-col">
        <div class="flex gap-4">
          <section class="w-full">
            <p class="font-medium mb-4">Latest Transport Application Details</p>
            <crud-field
              label="Acknowledged"
              [value]="!!transportApplication?.is_acknowledged ? 'Yes' : 'No'"
            />
            <crud-field
              label="Effective commencement date"
              [value]="
                dateFormat(
                  transportApplication?.acknowledgement
                    ?.effective_commencement_date
                )
              "
            />
            <crud-field
              label="Payable per month"
              [value]="transportApplication?.acknowledgement?.payable_per_month"
            />
            <crud-field
              label="Refundable deposit fee"
              [value]="
                transportApplication?.acknowledgement?.refundable_deposit_fee
              "
            />
          </section>
          <section class="w-full pt-8">
            <crud-field
              label="Withdrawn"
              [value]="
                !!transportApplication?.withdrawal &&
                transportApplication.status === 'Withdrawn'
                  ? 'Yes'
                  : 'No'
              "
            />
            <div
              *ngIf="
                !!transportApplication?.withdrawal &&
                transportApplication.status === 'Withdrawn'
              "
            >
              <crud-field
                label="Withdrawal date"
                [value]="dateFormat(transportApplication?.withdrawal?.date)"
              />
              <crud-field
                label="Withdrawal reason"
                [value]="transportApplication?.withdrawal?.withdrawal_reason"
              />
            </div>
          </section>
        </div>
        <!--  -->
        <div *ngIf="!!etsSubsidy">
          <mat-divider></mat-divider>
          <section class="flex flex-col gap-4 w-full">
            <section class="w-full flex gap-4">
              <div class="w-full">
                <p class="font-medium my-4">ETS Subsidy Assignment Details</p>
                <crud-field
                  label="Effective date"
                  [value]="dateFormat(etsSubsidy?.effective_date)"
                />
                <crud-field
                  label="End date"
                  [value]="dateFormat(etsSubsidy?.end_date)"
                />
                <crud-field
                  label="Need financial assistance"
                  [value]="
                    !!etsSubsidy?.need_financial_assistance ? 'Yes' : 'No'
                  "
                />
              </div>
              <section
                *ngIf="etsSubsidy?.ets_subsidy_tier as etsSubsidyTier"
                class="w-full"
              >
                <p class="font-medium my-4">ETS Subsidy Tier Details</p>
                <crud-field
                  label="Tier name"
                  [value]="etsSubsidyTier?.tier_name"
                />
                <crud-field
                  label="OOP per client"
                  [value]="etsSubsidyTier?.oop_per_client"
                />
                <crud-field
                  label="OOP per client w/GST"
                  [value]="etsSubsidyTier?.oop_per_client_with_gst"
                />
              </section>
            </section>
            <!--  -->
            <mat-divider
              *ngIf="
                !!etsSubsidy?.need_financial_assistance ||
                !!etsSubsidy?.deviated_ets_subsidy_tier
              "
            ></mat-divider>
            <!--  -->
            <section class="w-full flex gap-4">
              <div
                *ngIf="!!etsSubsidy?.need_financial_assistance"
                class="w-full"
              >
                <crud-field
                  label="Deviation reason"
                  [value]="etsSubsidy?.deviation_reason"
                />
                <crud-field
                  label="Deviated effective date"
                  [value]="dateFormat(etsSubsidy?.deviated_effective_date)"
                />
                <crud-field
                  label="Deviated end date"
                  [value]="dateFormat(etsSubsidy?.deviated_end_date)"
                />
              </div>
              <!--  -->
              <section
                *ngIf="etsSubsidy?.deviated_ets_subsidy_tier as etsSubsidyTier"
                class="w-full"
              >
                <p class="font-medium mb-4">
                  Deviated ETS Subsidy Tier Details
                </p>
                <crud-field
                  label="Tier name"
                  [value]="etsSubsidyTier?.tier_name"
                />
                <crud-field
                  label="OOP per client"
                  [value]="etsSubsidyTier?.oop_per_client"
                />
                <crud-field
                  label="OOP per client w/GST"
                  [value]="etsSubsidyTier?.oop_per_client_with_gst"
                />
              </section>
            </section>
          </section>
        </div>
      </section>
      <!--  -->
      <section *ngIf="!hasTransportApplication">
        <p class="font-medium">
          {{ client?.name_used_in_goals ?? client.name | titlecase }} has no
          assigned ETS Subsidy
        </p>
      </section>
    </app-panel>
    <!--  -->
    <!--  -->
    <app-panel title="Adhoc Subsidy Assignments">
      <section *ngIf="hasAdhocSubsidyAssignments">
        <div
          *ngFor="
            let adhocSubsidyAssignment of adhocSubsidyAssignments;
            let i = index
          "
          class="flex flex-col"
        >
          <div class="flex gap-4 w-full">
            <section class="w-full">
              <p class="font-medium mb-4">
                {{ i + 1 }}: Adhoc Subsidy Assignment Details
              </p>
              <crud-field
                label="Amount"
                [value]="adhocSubsidyAssignment?.amount"
              />
              <crud-field
                label="Start date"
                [value]="dateFormat(adhocSubsidyAssignment?.start_date)"
              />
              <crud-field
                label="End date"
                [value]="dateFormat(adhocSubsidyAssignment?.end_date)"
              />
            </section>
            <section
              *ngIf="adhocSubsidyAssignment?.adhoc_subsidy as adhocSubsidy"
              class="w-full"
            >
              <p class="font-medium mb-4">Adhoc Subsidy Details</p>
              <crud-field
                label="Programme name"
                [value]="adhocSubsidy?.project_name"
              />
              <crud-field label="Fund type" [value]="adhocSubsidy?.fund_type" />
              <crud-field
                label="Total amount for utilisation"
                [value]="adhocSubsidy?.amount"
              />
              <crud-field
                label="Start date"
                [value]="dateFormat(adhocSubsidy?.start_date)"
              />
              <crud-field
                label="End date"
                [value]="dateFormat(adhocSubsidy?.end_date)"
              />
            </section>
          </div>
        </div>
      </section>
      <section *ngIf="!hasAdhocSubsidyAssignments">
        <p class="font-medium">
          {{ client?.name_used_in_goals ?? client.name | titlecase }} has no
          assigned Adhoc Subsidies
        </p>
      </section>
    </app-panel>
    <!--  -->
    <!--  -->
    <section *ngIf="hasNoFeeMatters">
      <p class="font-medium">
        {{ client?.name_used_in_goals ?? client.name | titlecase }} has no
        assigned Means Test Tier, ETS Subsidy, nor Adhoc Subsidies
      </p>
    </section>
    <!--  -->
    <!--  -->
  </section>
  <!--  -->
</app-panel>
