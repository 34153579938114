/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const fixedNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard.group',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    tooltip: 'Dashboard',
    link: '/dashboard',
  },
  {
    id: 'referral.group',
    title: 'Referral',
    tooltip: 'Referral',
    type: 'collapsable',
    icon: 'mat_outline:description',
    children: [
      {
        id: 'e_referral',
        title: 'E-Referral',
        type: 'basic',
        icon: 'mat_outline:contact_mail',
        tooltip: 'E-Referral',
        link: '/e-referral',
      },
      {
        id: 'scheduer',
        title: 'Event Scheduler',
        icon: 'mat_outline:pending_actions',
        tooltip: 'Event Scheduler',
        type: 'basic',
        link: '/events',
      },
    ],
  },
  {
    id: 'client.group',
    title: 'Client',
    tooltip: 'Client',
    type: 'collapsable',
    icon: 'mat_outline:person',
    children: [
      {
        id: 'client_list',
        title: 'Client List',
        type: 'basic',
        icon: 'mat_outline:person',
        tooltip: 'Client List',
        link: '/students',
      },
      {
        id: 'withdrawal',
        title: 'Exit',
        type: 'basic',
        icon: 'mat_outline:backspace',
        tooltip: 'Exit',
        link: '/withdrawals',
      },
      {
        id: 'graduate',
        title: 'Graduate',
        type: 'basic',
        icon: 'mat_outline:school',
        tooltip: 'Graduate',
        link: '/graduate',
      },
      {
        id: 'iep',
        title: 'IEP Reports',
        type: 'basic',
        icon: 'mat_outline:folder_shared',
        tooltip: 'IEP Reports',
        link: '/iep',
      },
      {
        id: 'internal_transfer',
        title: 'Internal Transfer',
        type: 'basic',
        icon: 'mat_outline:transfer_within_a_station',
        tooltip: 'Internal Transfer',
        link: '/internal-transfer',
      },
      {
        id: 'leave_of_absence',
        title: 'Leave of Absence',
        type: 'basic',
        icon: 'mat_outline:person_remove_alt_1',
        tooltip: 'Leave of Absence',
        link: '/client-leave',
      },
      {
        id: 'client_absence',
        title: 'Absenteeism Form',
        type: 'basic',
        icon: 'mat_outline:event_busy',
        tooltip: 'Absenteeism Form',
        link: '/client-absence',
      },
      {
        id: 'caregiver_coaching_home_visit',
        title: 'Caregiver Coaching Home Visit',
        type: 'basic',
        icon: 'mat_outline:escalator_warning',
        tooltip: 'Caregiver Coaching Home Visit',
        link: '/caregiver-coaching-home-visit',
      },
      {
        id: 'caregiver_interview_form',
        title: 'Caregiver Interview Form',
        type: 'basic',
        icon: 'mat_outline:assignment',
        tooltip: 'Caregiver Interview Form',
        link: '/caregiver-interview',
      },
      {
        id: 'incident_report',
        title: 'Incident Report',
        type: 'basic',
        icon: 'mat_outline:personal_injury',
        tooltip: 'Incident Report',
        link: '/incident-report',
      },
    ],
  },
  {
    id: 'staff.group',
    title: 'Staff',
    type: 'collapsable',
    tooltip: 'Staff',
    icon: 'mat_outline:group',
    children: [
      {
        id: 'staff-members',
        title: 'Staff Members',
        type: 'basic',
        icon: 'mat_outline:supervisor_account',
        tooltip: 'Staff Members',
        link: '/staff',
      },
      {
        id: 'staff-team',
        title: 'Staff Team',
        type: 'basic',
        icon: 'mat_outline:groups',
        tooltip: 'Staff Team',
        link: '/staff-team',
      },
    ],
  },
  {
    id: 'transport.group',
    title: 'Transport',
    type: 'collapsable',
    tooltip: 'Transport',
    icon: 'heroicons_outline:truck',
    children: [
      {
        id: 'application',
        title: 'Applications',
        icon: 'mat_outline:description',
        tooltip: 'Applications',
        type: 'basic',
        link: '/transport/application-list',
      },
      {
        id: 'vehicle',
        title: 'Vehicles',
        icon: 'heroicons_outline:truck',
        tooltip: 'Vehicles',
        type: 'basic',
        link: '/transport/vehicle-list',
      },
    ],
  },
  {
    id: 'finance.group',
    title: 'Fee Records',
    type: 'collapsable',
    tooltip: 'Finance Records',
    icon: 'heroicons_outline:currency-dollar',
    children: [
      {
        id: 'finance-assignment',
        title: 'Finance Records',
        type: 'basic',
        tooltip: 'Finance Records',
        link: '/finance-assignment',
        icon: 'heroicons_outline:tag',
      },
      {
        id: 'finance-setting',
        title: 'Finance Settings',
        type: 'basic',
        tooltip: 'Finance Setting',
        link: '/finance-settings',
        icon: 'mat_outline:calculate',
      },
    ],
  },
  {
    id: 'classrooms.group',
    title: 'Classes',
    type: 'collapsable',
    tooltip: 'Classes',
    icon: 'mat_outline:school',
    children: [
      {
        id: 'Class',
        title: 'Classes',
        icon: 'mat_outline:meeting_room',
        tooltip: 'Classes',
        type: 'basic',
        link: '/classes',
      },
      {
        id: 'schedules',
        title: 'Class Sessions',
        icon: 'mat_outline:assignment',
        tooltip: 'Class Sessions',
        type: 'basic',
        link: '/schedules',
      },
    ],
  },
  {
    id: 'attendance',
    title: 'Attendance',
    type: 'basic',
    icon: 'mat_outline:checklist',
    tooltip: 'Attendance',
    link: '/attendance',
  },
  {
    id: 'communications.group',
    title: 'Communications',
    tooltip: 'Communications',
    type: 'collapsable',
    icon: 'heroicons_outline:speakerphone',
    children: [
      {
        id: 'broadcasts',
        title: 'Broadcast',
        type: 'basic',
        link: '/broadcasts',
        icon: 'mat_outline:rss_feed',
        tooltip: 'Broadcast',
      },
      {
        id: 'chat-history',
        title: 'Chat History',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt-2',
        tooltip: 'Chat History',
        link: '/chat-history',
      },
    ],
  },
  {
    id: 'reports.group',
    title: 'Report Generator',
    type: 'collapsable',
    tooltip: 'Report Generator',
    icon: 'mat_outline:folder_open',
    children: [
      {
        id: 'report-generator',
        title: 'Standard Reports',
        type: 'basic',
        tooltip: 'Standard Reports',
        icon: 'mat_outline:description',
        link: '/report-generator/standard',
      },
      {
        id: 'report-generator',
        title: 'Custom Reports',
        type: 'basic',
        tooltip: 'Custom Reports',
        icon: 'mat_outline:summarize',
        link: '/report-generator/custom',
      },
    ],
  },
  {
    id: 'centre.group',
    title: 'Centre',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    tooltip: 'Centre',
    link: '/centre-management',
  },
  {
    id: 'academic.group',
    title: 'Events',
    tooltip: 'Events',
    type: 'collapsable',
    icon: 'heroicons_outline:calendar',
    children: [
      // {
      //   id: 'academicYear',
      //   title: 'Academic Years',
      //   type: 'basic',
      //   icon: 'mat_outline:date_range',
      //   tooltip: 'Academic Years',
      //   link: 'academic-year',
      // },
      {
        id: 'calendarEvent',
        title: 'Intervention Events',
        icon: 'mat_outline:event',
        tooltip: 'Intervention Events',
        type: 'basic',
        link: '/calendar-events',
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'basic',
    tooltip: 'Settings',
    icon: 'mat_outline:settings',
    link: '/settings',
  },
];
export const defaultNavigation: FuseNavigationItem[] = fixedNavigation;
export const compactNavigation: FuseNavigationItem[] = fixedNavigation;
export const futuristicNavigation: FuseNavigationItem[] = fixedNavigation;
export const horizontalNavigation: FuseNavigationItem[] = fixedNavigation;
