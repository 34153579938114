export enum PermissionList {
  // ROLES - GOOD
  VIEW_ROLE = 'view role',
  VIEW_ROLE_AUDITS = 'view role audits',
  CREATE_ROLE = 'create role',
  UPDATE_ROLE = 'update role',

  // E-REFFERAL - GOOD
  VIEW_E_REFERRAL = 'view e-referral',
  CREATE_E_REFERRAL = 'create e-referral',
  UPDATE_E_REFERRAL = 'update e-referral',
  CANCEL_E_REFERRAL = 'cancel e-referral',
  SEND_ACK_LETTER = 'send acknowledgement letter',
  SEND_CENTRE_VISIT_INVITE = 'send centre visit invite',
  SEND_INITIAL_SCREENING_INVITE = 'send initial screening invite',
  SEND_CONTRACT_SIGNING_INVITE = 'send contract signing invite',
  SEND_COMMENCEMENT_LETTER = 'send commencement letter',
  UPDATE_EREFERRAL_ATTACHMENT_FILES = 'update e-referral attachment files',
  ACCESS_EREFERRAL_FORMS = 'access e-referral forms',

  // AUDIT - GOOD
  VIEW_AUDIT_TRAIL = 'view audit trail',

  // STAFF - GOOD
  VIEW_STAFF = 'view staff',
  CREATE_STAFF = 'create staff',
  UPDATE_STAFF = 'update staff',

  // STAFF TEAM - GOOD
  VIEW_STAFF_TEAM = 'view staff team',
  CREATE_STAFF_TEAM = 'create staff team',
  UPDATE_STAFF_TEAM = 'update staff team',
  ASSIGN_STAFF_TO_TEAM = 'assign staff to team',

  // CENTER - GOOD
  VIEW_CENTRE = 'view centre',
  CREATE_CENTRE = 'create centre',
  UPDATE_CENTRE = 'update centre',

  // ATTENDANCE - GOOD
  VIEW_ATTENDANCE = 'view attendance',
  CREATE_ATTENDANCE = 'create attendance',
  UPDATE_ATTENDANCE = 'update attendance',
  DELETE_ATTENDANCE = 'delete attendance',

  // SCHEDULE - GOOD
  VIEW_CLASS_SESSION = 'view class session',
  CREATE_CLASS_SESSION = 'create class session',
  UPDATE_CLASS_SESSION = 'update class session',
  ARCHIVE_CLASS_SESSION = 'archive class session',
  END_CLASS_SESSION = 'end class session',
  ASSIGN_CLIENT_TO_CLASS_SESSION = 'assign client to class session',

  // CLIENT - GOOD
  VIEW_CLIENT = 'view client',
  // CREATE_CLIENT = 'create client',
  UPDATE_CLIENT = 'update client',
  EXPORT_CUSTOMER_CREATION_TEMPLATE = 'export client customer creation template',
  UPDATE_CLIENT_FILES = 'update client files',
  REMOVE_CLIENT_FILES = 'remove client files',
  VIEW_CLIENT_FEE_MATTERS = 'view client fee matters',
  // DELETE_CLIENT = 'delete client',
  // COMMUNICATION LOG - GOOD
  VIEW_COMMUNICATION_LOG = 'view communication log',
  CREATE_COMMUNICATION_LOG = 'create communication log',
  UPDATE_COMMUNICATION_LOG = 'update communication log',
  DELETE_COMMUNICATION_LOG = 'delete communication log',

  // ACADEMIC YEAR - GOOD
  VIEW_ACADEMIC_YEAR = 'view academic year',
  CREATE_ACADEMIC_YEAR = 'create academic year',
  UPDATE_ACADEMIC_YEAR = 'update academic year',

  // EMAIL BROADCAST - GOOD
  VIEW_EMAIL_BROADCAST = 'view broadcast',
  CREATE_EMAIL_BROADCAST = 'create broadcast',
  DELETE_EMAIL_BROADCAST = 'delete broadcast',
  UPDATE_EMAIL_BROADCAST = 'update broadcast',

  // EVENT - GOOD
  VIEW_EVENT = 'view event',
  CREATE_EVENT = 'create event',
  UPDATE_EVENT = 'update event',
  CANCEL_EVENT = 'cancel event',

  CREATE_INTERVENTION_EVENT = 'create intervention event',
  CREATE_CALENDAR_EVENT = 'create calendar event',
  DUPLICATE_EVENT = 'duplicate event',
  EDIT_SESSION_TIME_ON_OR_AFTER_SESSION_START_DATE = 'edit session time on or after session start date',

  // SETTING - GOOD
  VIEW_SETTING = 'view setting',
  CREATE_SETTING = 'create setting',
  UPDATE_SETTING = 'update setting',

  // WITHDRAWAL - GOOD
  VIEW_WITHDRAWAL = 'view exit',
  VIEW_BANK_ACCOUNT_NUMBER_EXIT = 'view bank account number exit',
  CREATE_WITHDRAWAL = 'create exit',
  UPDATE_WITHDRAWAL = 'update exit',
  CANCEL_WITHDRAWAL = 'cancel exit',
  RESEND_WITHDRAWAL = 'resend exit',
  EXPORT_WITHDRAWAL = 'export exit',
  WITHDRAWAL_SUBMIT_ON_CAREGIVER_BEHALF = 'exit submit on behalf of caregiver',

  // GRADUATE - GOOD
  VIEW_GRADUATE = 'view graduate',
  CREATE_GRADUATE = 'create graduate',
  UPDATE_GRADUATE = 'update graduate',
  CANCEL_GRADUATE = 'cancel graduate',
  RESEND_GRADUATE = 'resend graduate',
  EXPORT_GRADUATE = 'export graduate',
  VIEW_BANK_ACCOUNT_NUMBER_GRADUATE = 'view bank account number graduate',
  GRADUATE_UPLOAD_FILE = 'graduate upload file',
  GRADUATE_REMOVE_FILE = 'graduate remove file',
  GRADUATE_SUBMIT_ON_CAREGIVER_BEHALF = 'graduate submit on behalf of caregiver',

  // JUMPSTART PACKAGE - GOOD
  UPLOAD_JUMPSTART_PACKAGE_FILE = 'upload jumpstart package file',
  DELETE_JUMPSTART_PACKAGE_FILE = 'delete jumpstart package file',

  // CHAT - GOOD
  VIEW_CHAT = 'view chat',
  VIEW_ALL_CHAT = 'view all chat',
  EXPORT_CHAT_ROOM_MESSAGES = 'export chat room messages',

  // CLASSROOM - GOOD
  VIEW_CLASSROOM = 'view class',
  CREATE_CLASSROOM = 'create class',
  UPDATE_CLASSROOM = 'update class',

  // CAREGIVER - GOOD
  VIEW_CAREGVIER = 'view caregiver',
  CREATE_CAREGVIER = 'create caregiver',
  UPDATE_CAREGVIER = 'update caregiver',
  DELETE_CAREGVIER = 'delete caregiver',

  // USER - GOOD
  UPLOAD_USER_PROFILE_PICTURE = 'upload user profile picture',

  // INTERNAL TRANSFER
  VIEW_INTERNAL_TRANSFER = 'view internal transfer',

  CREATE_CAREGIVER_REQUEST = 'create internal transfer caregiver request',
  CREATE_TEAM_REQUEST = 'create internal transfer team request',
  CREATE_EI_UNDER_TWO_REQUEST = 'create internal transfer ei_under_2 request',

  UPDATE_INTERNAL_TRANSFER_CAREGIVER_FORMS = 'update internal transfer caregiver forms',
  UPDATE_INTERNAL_TRANSFER_TEAM_FORMS = 'update internal transfer team forms',
  UPDATE_INTERNAL_TRANSFER_EI_UNDER_TWO_FORMS = 'update internal transfer ei_under_2 forms',

  UPDATE_INTERNAL_TRANSFER_SECTION_A_B = 'update internal transfer section_A_B',
  UPDATE_INTERNAL_TRANSFER_SECTION_C = 'update internal transfer section_C',
  UPDATE_INTERNAL_TRANSFER_SECTION_D = 'update internal transfer section_D',

  CANCEL_INTERNAL_TRANSFER = 'cancel internal transfer',

  EXPORT_INTERNAL_TRANSFER = 'export internal transfer',
  SEND_INTERNAL_TRANSFER_APPROVED_LETTER = 'send internal transfer approved letter',
  SEND_INTERNAL_TRANSFER_CAREGIVER_RESPONSE_LETTER = 'send internal transfer caregiver response letter',
  INTERNAL_TRANSFER_SUBMIT_ON_CAREGIVER_BEHALF = 'internal transfer submit on behalf of caregiver',

  // Incident Report
  VIEW_INCIDENT_REPORT = 'view incident report',

  CREATE_INCIDENT_REPORT = 'create incident report',
  UPDATE_INCIDENT_REPORT = 'update incident report',

  CREATE_INCIDENT_REPORT_CLINICAL_FINDINGS = 'create incident report clinical findings',
  UPDATE_INCIDENT_REPORT_CLINICAL_FINDINGS = 'update incident report clinical findings',

  DELETE_INCIDENT_REPORT = 'delete incident report',

  UPDATE_INCIDENT_REPORT_SIGNATURES = 'update incident report signatures',

  EXPORT_INCIDENT_REPORT = 'export incident report',

  // IEP
  VIEW_CLIENT_REPORT = 'view client report',
  // -----------------------------------------------------------
  VIEW_PRE_ASSESSMENT = 'view iep pre-assessment',
  VIEW_GCO = 'view iep gco',
  VIEW_ASSESSMENT = 'view iep assessment',
  // -----------------------------------------------------------
  CREATE_PRE_ASSESSMENT = 'create iep pre-assessment',
  CREATE_GCO = 'create iep gco',
  // -----------------------------------------------------------
  UPDATE_PRE_ASSESSMENT = 'update iep pre-assessment',
  UPDATE_PRE_ASSESSMENT_STAFF_SIGNATURE = 'update iep pre-assessment staff signature',
  UPDATE_ASSESSMENT_STAFF_SIGNATURE = 'update iep assessment staff signature',
  UPDATE_GCO = 'update iep gco',
  UPDATE_ASSESSMENT_GOALS = 'update iep assessment goals',
  UPDATE_ASSESSMENT_BEHAVIOUR_DESCRIPTORS = 'update iep assessment behaviour descriptors',
  UPDATE_ASSESSMENT_SHORT_TERM_OBJECTIVES = 'update iep assessment short term objectives',
  UPDATE_ASSESSMENT_PART_5 = 'update iep assessment part 5',
  UPDATE_ASSESSMENT_PART_6 = 'update iep assessment part 6',
  UPDATE_ASSESSMENT_PROGRESS_SUMMARY = 'update iep assessment progress summary',
  UPDATE_ASSESSMENT_INTERVENTION_PLAN = 'update iep assessment intervention plan',
  UPDATE_NARRATIVE = 'update iep narrative',
  // -----------------------------------------------------------
  DELETE_PRE_ASSESSMENT = 'delete iep pre-assessment',
  DELETE_GCO = 'delete iep gco',
  DELETE_ASSESSMENT = 'delete iep assessment',
  DELETE_NARRATIVE = 'delete iep narrative',
  // -----------------------------------------------------------
  EXPORT_PRE_ASSESSMENT = 'export iep pre-assessment',
  EXPORT_ASSESSMENT = 'export iep assessment',
  // -----------------------------------------------------------
  VIEW_FINANCE_SETTINGS = 'view finance settings',
  IMPORT_PROGRAMME_FEE_TABLE = 'import programme fee table',
  EXPORT_PROGRAMME_FEE_TABLE = 'export programme fee table',
  IMPORT_TRANSPORT_FEE_TABLE = 'import transport fee table',
  EXPORT_TRANSPORT_FEE_TABLE = 'export transport fee table',
  CREATE_ADHOC_SUBSIDY = 'create adhoc subsidy',
  UPDATE_ADHOC_SUBSIDY = 'update adhoc subsidy',
  DELETE_ADHOC_SUBSIDY = 'delete adhoc subsidy',
  VIEW_FINANCING = 'view financing',
  CREATE_MEANS_TEST_TIER_ASSIGNMENT = 'create means test tier assignment',
  UPDATE_MEANS_TEST_TIER_ASSIGNMENT = 'update means test tier assignment',
  DELETE_MEANS_TEST_TIER_ASSIGNMENT = 'delete means test tier assignment',
  CREATE_ADHOC_SUBSIDY_ASSIGNMENT = 'create adhoc subsidy assignment',
  UPDATE_ADHOC_SUBSIDY_ASSIGNMENT = 'update adhoc subsidy assignment',
  DELETE_ADHOC_SUBSIDY_ASSIGNMENT = 'delete adhoc subsidy assignment',
  // -----------------------------------------------------------
  VIEW_CAREGIVER_INTERVIEW = 'view caregiver interview',

  CREATE_CAREGIVER_INTERVIEW_CONSENT = 'create caregiver interview consent form',
  UPDATE_CAREGIVER_INTERVIEW_CONSENT = 'update caregiver interview consent form',

  CREATE_CAREGIVER_INTERVIEW_FORM = 'create caregiver interview form',
  UPDATE_CAREGIVER_INTERVIEW_FORM = 'update caregiver interview form',

  UPDATE_CAREGIVER_ROUTINES_INFORMATION_FORM = 'update caregiver routines information form',

  UPDATE_CAREGIVER_INTERVIEW_FORM_FINALIZE = 'update caregiver interview form finalize',

  UPDATE_CAREGIVER_INTERVIEW_ACKNOWLEDGEMENT_SIGNATURE = 'update caregiver interview acknowledgement signature',

  CAREGIVER_INTERVIEW_RESPONSE_SUBMIT_ON_BEHALF = 'caregiver interview response submit on behalf',
  // -----------------------------------------------------------
  VIEW_CAREGIVER_COACHING = 'view caregiver coaching',

  CREATE_CAREGIVER_COACHING_CONSENT_FORM = 'create caregiver coaching consent form',
  UPDATE_CAREGIVER_COACHING_CONSENT_FORM = 'update caregiver coaching consent form',

  CREATE_CAREGIVER_COACHING_HOME_VISIT_FORM = 'create caregiver coaching home visit form',
  UPDATE_CAREGIVER_COACHING_HOME_VISIT_FORM = 'update caregiver coaching home visit form',

  CREATE_CAREGIVER_COACHING_INFORMATION_SHARING_FORM = 'create caregiver coaching information sharing form',
  UPDATE_CAREGIVER_COACHING_INFORMATION_SHARING_FORM = 'update caregiver coaching information sharing form',

  UPDATE_CAREGIVER_COACHING_ACKNOWLEDGEMENT_SIGNATURE = 'update caregiver coaching acknowledgement signature',
  // -----------------------------------------------------------
  VIEW_LEAVE_OF_ABSENCE_FORM = 'view leave of absence form',
  CREATE_LEAVE_OF_ABSENCE_FORM = 'create leave of absence form',
  UPDATE_LEAVE_OF_ABSENCE_FORM = 'update leave of absence form',
  CANCEL_LEAVE_OF_ABSENCE_FORM = 'cancel leave of absence form',

  UPDATE_LEAVE_OF_ABSENCE_FORM_ACKNOWLEDGEMENT_SIGNATURE = 'update leave of absence form acknowledgement signature',
  UPDATE_LEAVE_OF_ABSENCE_FORM_FILES = 'update leave of absence form files',

  EXPORT_LEAVE_OF_ABSENCE = 'export leave of absence',
  // -----------------------------------------------------------
  VIEW_REPORT_GENERATOR = 'view report generator',
  GENERATE_EPES_REPORT = 'generate epes report',
  GENERATE_SO_REPORT = 'generate so report',
  GENERATE_GRADUATE_REPORT = 'generate graduate report',
  GENERATE_INTERNAL_TRANSFER_REPORT = 'generate internal transfer report',
  GENERATE_WITHDRAWAL_REPORT = 'generate exit report',
  GENERATE_EREFERRAL_REPORT = 'generate ereferral report',
  GENERATE_MEANS_TEST_TIER_EXPIRY_REPORT = 'generate means test tier expiry report',
  GENERATE_CLIENT_DETAILS_REPORT = 'generate client details report',
  GENERATE_STAFF_DETAILS_REPORT = 'generate staff details report',
  GENERATE_STAFF_TEAM_DETAILS_REPORT = 'generate staff team details report',
  GENERATE_CLIENT_ATTENDANCE_REPORT = 'generate client attendance report',
  GENERATE_CLIENT_ABSENTEEISM_REPORT = 'generate client absenteeism report',
  GENERATE_CLASS_DETAILS_REPORT = 'generate class details report',
  GENERATE_CLASS_SESSION_DETAILS_REPORT = 'generate class session details report',
  GENERATE_CENTRE_DETAILS_REPORT = 'generate centre details report',
  GENERATE_ACADEMIC_YEAR_REPORT = 'generate academic year report',
  // -----------------------------------------------------------
  VIEW_CLIENT_LEAVE_OF_ABSENCE = 'view client leave of absence',

  CREATE_CLIENT_LEAVE_OF_ABSENCE = 'create client leave of absence',

  UPDATE_CLIENT_LEAVE_OF_ABSENCE = 'update client leave of absence',

  DELETE_CLIENT_LEAVE_OF_ABSENCE = 'delete client leave of absence',
  // -----------------------------------------------------------
  VIEW_TRANSPORT_APPLICATION = 'view transport application',
  CREATE_TRANSPORT_APPLICATION = 'create transport application',
  UPDATE_TRANSPORT_APPLICATION = 'update transport application',
  CANCEL_TRANSPORT_APPLICATION = 'cancel transport application',
  WITHDRAW_TRANSPORT_APPLICATION = 'withdraw transport application',
  // -----------------------------------------------------------
  VIEW_TRANSPORT_VEHICLE = 'view transport vehicle',
  CREATE_TRANSPORT_VEHICLE = 'create transport vehicle',
  UPDATE_TRANSPORT_VEHICLE = 'update transport vehicle',
  // -----------------------------------------------------------
  VIEW_DASHBOARD = 'view dashboard',
  // -----------------------------------------------------------
  VIEW_MASKED_BIRTH_CERT = 'view masked birth certificate',
  // -----------------------------------------------------------
  VIEW_MASS_TRANSFER = 'view mass transfer',
  CREATE_MASS_TRANSFER = 'create mass transfer',
  UPDATE_MASS_TRANSFER = 'update mass transfer',
  CANCEL_MASS_TRANSFER = 'cancel mass transfer',
  EDIT_MASS_TRANSFER_MTT_TO_EMAIL = 'edit mass transfer mtt to email',
  VERIFY_MASS_TRANSFER = 'verify mass transfer',
  // -----------------------------------------------------------
  VIEW_DOCUMENT_HUB = 'view document hub',
  CREATE_DOCUMENT_HUB = 'create document hub',
  UPDATE_DOCUMENT_HUB = 'update document hub',
  DELETE_DOCUMENT_HUB = 'delete document hub',
  // -----------------------------------------------------------
  SIGN_ON_BEHALF = 'sign on behalf',
}
