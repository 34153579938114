import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { dateFormatter } from '../utils/date-format';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'month-picker',
  template: `<div class="mb-4">
    <label [for]="controlName" class="block text-sm font-medium text-gray-700">
      {{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <div
      class="flex h-9 w-full border border-gray-300 px-4 items-center rounded-md"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
    >
      <input
        matInput
        [matDatepicker]="date"
        [placeholder]="placeholder"
        [id]="controlName"
        [name]="controlName"
        [formControl]="controlName"
      />
      <mat-datepicker-toggle [for]="date" class="ml-auto">
        <mat-icon
          svgIcon="feather:calendar"
          matDatepickerToggleIcon
          class="text-blue-500"
        ></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker
        #date
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, date)"
        (closed)="onDateSelected()"
      ></mat-datepicker>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="controlName.invalid && controlName.touched"
    >
      <div
        *ngIf="controlName.errors.required"
        class="text-sm text-red-500 mt-1 ml-2"
      >
        This field is required.
      </div>
    </div>
  </div>`,
})
export class MonthPickerComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = 'MM/YYYY';
  @Input() alwaysFirstDayOfMonth: boolean = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (this.controlName.value) {
      this.onDateSelected();
    }
  }

  public onDateSelected(): void {
    this.controlName.setValue(
      dateFormatter(
        this.datePipe,
        this.controlName.value,
        !this.alwaysFirstDayOfMonth ? 'yyyy-MM-dd' : 'yyyy-MM-01'
      )
    );
    this.onSelect.emit(this.controlName.value);
  }

  public setMonthAndYear(normalizedMonthAndYear: any, datepicker: any): void {
    const ctrlValue = new Date();
    ctrlValue.setMonth(normalizedMonthAndYear.month());
    ctrlValue.setFullYear(normalizedMonthAndYear.year());

    this.controlName.setValue(ctrlValue);

    datepicker.close();
  }

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
