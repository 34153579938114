import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/services/api/models/user';
import { environment } from 'environments/environment';
import { Observable, catchError, delay, of, switchMap, throwError } from 'rxjs';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private base = '';
  protected headers = {};
  private isLogoutInProgress = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _router: Router
  ) {
    this.base = environment.apiUrl;
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ${this.accessToken}`);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set localStorageUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    const payload = {
      email: email,
    };

    return this._httpClient.post(this.base + '/reset-password-token', payload);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(payload: {}): Observable<any> {
    return this._httpClient.post(this.base + '/reset-password', payload);
  }

  /**
   * Change password
   *
   * @param password
   */
  changePassword(payload: {}): Observable<any> {
    return this._httpClient.post(this.base + '/change-password', payload);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(this.base + '/login', credentials).pipe(
      switchMap((response: any) => {
        this.localStorageUser = response.data.user;
        // Store the access token in the local storage
        this.accessToken = response.data.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.data.user;

        // Return a new observable with the response
        return of(response.data);
      })
    );
  }

  /**
   * Sign in using the Microsoft SSO
   */
  signInUsingMicrosoft(queryParams): Observable<any> {
    return this._httpClient
      .get(this.base + '/login-microsoft-sso/callback', {
        params: queryParams,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.data.token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.data.user;

          // Return a new observable with the response
          return of(response.data);
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post(this.base + '/me', {
        headers: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          this.localStorageUser = response;
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    if (this.isLogoutInProgress) {
      return of(false);
    }

    this.isLogoutInProgress = true;
    this._httpClient
      .post(this.base + '/logout', {})
      .pipe(delay(1000))
      .subscribe({
        next: () => {
          // Remove the access token from the local storage
          localStorage.removeItem('accessToken');
          sessionStorage.clear();

          // Set the authenticated flag to false
          this._authenticated = false;
          // Reset the flag
          this.isLogoutInProgress = false;
        },
        error: () => {
          // Remove the access token from the local storage
          localStorage.removeItem('accessToken');
          localStorage.removeItem('user');
          sessionStorage.clear();

          // Set the authenticated flag to false
          this._authenticated = false;
          // Reset the flag
          this.isLogoutInProgress = false;
        },
      });
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //   return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
