<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative rounded-full">
    <div class="rounded-full h-7 w-7 overflow-hidden">
      <img
        *ngIf="showAvatar && user?.profile_picture"
        class="object-cover object-center h-full w-full"
        [src]="user.profile_picture | secure | async"
      />
    </div>
    <mat-icon
      class="absolute inset-0 w-full h-full"
      *ngIf="!showAvatar || !user?.profile_picture"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
    <span
      class="right-0 bottom-0 w-2 h-2 rounded-full"
      [ngClass]="{
        'mr-px mb-px': !showAvatar || !user?.profile_picture,
        'bg-green-500': user?.status === 'online',
        'bg-amber-500': user?.status === 'away',
        'bg-red-500': user?.status === 'busy',
        'bg-gray-400': user?.status === 'not-visible'
      }"
    ></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <mat-divider class="my-2"></mat-divider>

  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>

  <button mat-menu-item (click)="updatePassword()">
    <mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
    <span>Update Password</span>
  </button>
</mat-menu>
